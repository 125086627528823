* {
	box-sizing: border-box;
}

.mjwi-register-wrapper button:disabled {
    opacity: 0.5;
}

.mjwi-register-wrapper {
	background: var(--primary-color);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: calc(100vh - 104px);
}

.mjwi-register-wrapper h1 {
	font-weight: bold;
	margin: 0 0 20px;
}

.mjwi-register-wrapper h2 {
	text-align: center;
}

.mjwi-register-wrapper p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.mjwi-register-wrapper span {
	font-size: 12px;
}

.mjwi-register-wrapper a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.mjwi-register-wrapper button {
	border-radius: 100px;
	border: 1px solid;
	background-color: var(--secondary-color);
	border-color: var(--secondary-color);
	color: #FFFFFF;
	font-size: 15px;
	font-family: 'Montserrat';
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin: 10px 0;
	cursor: pointer;
}

.mjwi-register-wrapper button:active {
	transform: scale(0.95);
}

.mjwi-register-wrapper button:focus {
	outline: none;
}

.mjwi-register-wrapper button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.mjwi-register-wrapper form {
	background-color: var(--light-color);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}
.mjwi-register-usrname, .mjwi-register-emailadrs, .mjwi-register-passwrd {
    display: flex;
    background: var(--dark-color);
    border-radius: 100px;
    padding: 5px 10px 5px 15px;
    color: var(--light-color);
    width: 100%;
    margin-bottom: 10px;
}
.mjwi-register-usrname i, .mjwi-register-emailadrs i, .mjwi-register-passwrd i {
    align-self: center;
    width: 10%;
}
.mjwi-register-wrapper input {
    background-color: transparent;
    border: none;
    padding: 10px 15px;
    width: 90%;
    color: var(--light-color);
    font-size: 15px;
    font-family: 'Montserrat';
}
.mjwi-register-wrapper input:-webkit-autofill,
.mjwi-register-wrapper input:-webkit-autofill:hover, 
.mjwi-register-wrapper input:-webkit-autofill:focus, 
.mjwi-register-wrapper input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--dark-color) inset !important;
	-webkit-text-fill-color: #ffffff;
}
.mjwi-register-wrapper input:focus-visible {
	outline: none;
}
.mjwi-rg-container {
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.mjwi-rg-form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.mjwi-rg-sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.mjwi-rg-container.right-panel-active .mjwi-rg-sign-in-container {
	transform: translateX(100%);
}

.mjwi-rg-sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.mjwi-rg-container.right-panel-active .mjwi-rg-sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.mjwi-rg-overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.mjwi-rg-container.right-panel-active .mjwi-rg-overlay-container{
	transform: translateX(-100%);
}

.mjwi-rg-overlay {
	background: var(--dark-color);
	/*background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);*/
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.mjwi-rg-container.right-panel-active .mjwi-rg-overlay {
  	transform: translateX(50%);
}

.mjwi-rg-overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.mjwi-rg-overlay-left {
	transform: translateX(-20%);
}

.mjwi-rg-container.right-panel-active .mjwi-rg-overlay-left {
	transform: translateX(0);
}

.mjwi-rg-overlay-right {
	right: 0;
	transform: translateX(0);
}

.mjwi-rg-container.right-panel-active .mjwi-rg-overlay-right {
	transform: translateX(20%);
}

.mjwi-rg-social-container {
	margin: 20px 0;
}

.mjwi-rg-social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.mjwi-frgt-pswrd {
    margin-top: 10px;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
}
.mjwi-forgotpwd {
    width: 100%;
}
.mjwi-register-wrapper .mjwi-forgotpwd input {
    margin-bottom: 25px;
    background: var(--dark-color);
    border-radius: 100px;
	width: 100%;
}
.mjwi-register-wrapper a.mjwi-btn{
	border-radius: 20px;
	height: auto;
	font-size: 15px;
	color:#fff;
	width: 100%;
	margin: 0;
}
.mjwi-forgotpwd .mjwi-row, .deleteAcntBtns {
    gap: 2%;
}
.dlt-acnt-pp-inner, .dlt-acnt-pp-inner #mjwi-btn-Delete, .dlt-acnt-pp-inner #mjwi-btn-Cancel {
    width: 100%;
}
