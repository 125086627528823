:root {
    --primary-color: #000000;
    --secondary-color: #ff6600;
    --dark-color: #222222;
    --hover-color: #ff6600;
    --light-color:#fafafc;
    --linear-gradient: linear-gradient(to right, #000000, #444444);
    --gap: 20px;
}

/* ----------------- Google Fonts ----------------- */
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Dancing+Script&family=Lato&family=Montserrat&family=Noto+Sans&family=Open+Sans&family=Poppins&family=Quicksand&family=Roboto&family=Roboto+Condensed&family=Ubuntu&display=swap');

.App {
    font-family: 'Montserrat';
}

/* ----------------- Accordion ----------------- */
.accordion__button {
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--light-color);
  font-weight: 600;
  letter-spacing: 0.7px;
  background: #000;
  font-size: 15px;
}
.accordion__button:hover, .accordion__button[aria-expanded='true']{
    color: var(--secondary-color);
    border-color: var(--secondary-color);
}
.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}
.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}
.mjwi-acc-panel-wrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0;
}
.mjwi-sub-accordion .mjwi-acc-panel-wrapper {
  border: 1px solid var(--dark-color);
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 10px;
}
.mjwi-sub-accordion .accordion__button {
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 0;
}
.mjwi-sub-accordion .accordion__button:before {
  height: 7px;
  width: 7px;
}
/* ----------------- GRID ---------------------- */
.mjwi-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mjwi-col10 {
    width: 10%;
}
.mjwi-col25 {
  width: 25%;
}
.mjwi-col33 {
  width: 33.3333%;
}
.mjwi-col50 {
  width: 50%;
}
.mjwi-col66 {
  width: 66.6666%;
}
.mjwi-col75 {
  width: 75%;
}
.mjwi-col90 {
    width: 90%;
}
.mjwi-col100 {
  width: 100%;
}
.mjwi-flex-end {
  justify-content: flex-end;
}
/***** Navbar *****/
.mjwi-nav-profile-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}
.mjwi-navbar {
    display: flex;
    justify-content: space-between;
    background: var(--dark-color);
    padding: 5px 20px;
}
img.mjwi-logo {
  width: 150px;
}
.mjwi-navbar-links {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 10px;
}

.mjwi-nav-link {
    color: white;
    text-decoration: none;
}
.mjwi-nav-link:hover, .mjwi-nav-link:active {
  color: var(--hover-color);
}

.mjwi-dropdown-content {
  position: absolute;
  background-color: var(--dark-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 20px;
  display: flex;
  flex-direction: column;
  top: 52px;
  text-align: right;
  width: 175px;
}
.mjwi-dropdown-item {
  color: var(--light-color);
  padding: 10px 20px;
  border-bottom: 1px solid;
}
  .mjwi-dropdown-item:hover {
    cursor: pointer;
    color: var(--hover-color)
  }
  .mjwi-dropdown-content a{
    text-decoration: none;
    color: var(--primary-color);
  }
  .profile-dd {
    cursor: pointer;
    display: flex;
    gap: 5px;
}
.profile-dd:active {
  color: var(--hover-color);
}
  .mjwi-nav-link i {
    font-size: 20px;
    cursor: pointer;
  }
  .mjwi-dropdown-item i {
    float: left;
    font-size: 17px;
    margin-top: 1.5px;
}
/* ---------------- Scroll-Bar CSS -------------- */
.mjwi-widgets-wrapper::-webkit-scrollbar, .mjwi-ct-container::-webkit-scrollbar, .mjwi-editor-display::-webkit-scrollbar {
  width: 10px;
}
.mjwi-editor-main, .mjwi-acc-panel-wrapper, .mjwi-setting-page {
  scrollbar-width: none;
}
/*---- Dark Scroller ---*/
.mjwi-widgets-wrapper::-webkit-scrollbar-track, .mjwi-ct-container::-webkit-scrollbar-track {
  background: var(--primary-color);
}
.mjwi-widgets-wrapper::-webkit-scrollbar-thumb, .mjwi-ct-container::-webkit-scrollbar-thumb {
  background: var(--dark-color);
  border-radius: 100px;
}
.mjwi-widgets-wrapper::-webkit-scrollbar-thumb:hover, .mjwi-ct-container::-webkit-scrollbar-thumb:hover {
  background: var(--light-color);
}
/*---- White Scroller ---*/
.mjwi-editor-display::-webkit-scrollbar-track {
  background: var(--light-color);
}
.mjwi-editor-display::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 100px;
}
.mjwi-editor-display::-webkit-scrollbar-thumb:hover {
  background: var(--dark-color);
}
/* --------------- Scroll-Bar Ends --------*/
/***** Widget Wrapper *****/
.mjwi-add-widget-btn {
    width: 150px;
    padding: 10px;
    margin-inline: auto;
    border-radius: 10px;
    background-color: var(--primary-color);
    text-align: center;
    box-shadow: 0 0 3px var(--primary-color);
}
.mjwi-add-widget-btn:hover {
    background-color: var(--hover-color);
    box-shadow: 0 0 5px var(--hover-color);
    cursor: pointer;
}
.mjwi-add-widget-btn a{
    text-decoration: none;
    color: white;
}
.mjwi-widgets-wrapper, .mjwi-setting-page {
  display: flex;
  height: calc(100vh - 104px);
  background-color: var(--primary-color);
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  gap: 30px;
}
.mjwi-user-widgets-banner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
}
.mjwi-widgets-wrapper .mjwi-ct-card {
  cursor: auto;
  min-height: auto;
}
.mjwi-widgets-leftbar {
  height: calc(100vh - 104px);
    width: 10%;
    background-color: var(--primary-color);
}

.mjwi-widget-list{
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
}
.mjwi-popup.mjwi-delete-pp {
  flex-direction: column;
  border-radius: 0.75rem;
}
/* ------------------ CT Banner -------------------- */

.mjwi-promo-banner * {
  color: var(--light-color);
  text-align: center;
  font-weight: lighter;
}
.mjwi-promo-banner h1 {
  font-size: 50px;
  margin-bottom: 0;
}
.mjwi-promo-banner h3{
  font-size: 24px;
}
/***** Catalog *****/
.mjwi-ct-container {
    display: flex;
    height: calc(100vh - 104px); 
    justify-content: center;
    overflow-y: scroll;
    background: var(--primary-color);
}
.mjwi-ct-list {
  width: 1280px;
  gap: 30px;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 30px;
  margin: 0 auto;
}
.mjwi-ct-menu__title {
    font-size: 18px;
    margin-bottom: 10px;
}

.mjwi-ct-menu__list {
    list-style: none;
    padding: 0;
}

.mjwi-ct-menu__item {
    margin-bottom: 8px;
    cursor: pointer;
}
.mjwi-ct-menu__item.mjwi-active {
  color: var(--hover-color);
}
.mjwi-widgets-wrapper .mjwi-ct-card-banner {
  display: none;
}
.mjwi-ct-container .mjwi-ct-card-banner {
  height: 247px;
}
.mjwi-ct-card {
  cursor: pointer;
  border-radius:  0.75rem;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  background: var(--dark-color);
  color: var(--light-color);
  width: 405px;
  min-height: 474px;
}
.mjwi-ct-card > * + * {
  margin-top: 1.1em;
}
img.mjwi-catalog-banner {
  width: 100%;
  border-radius: 0.75rem 0.75rem 0 0;
}
.mjwi-catalog-logo {
    width: 32px;
}
.mjwi-ct-list a {
  text-decoration: none;
}
.mjwi-ct-card-desc {
  margin: 0;
  padding: 0 10px;
  line-height: 1.5;
  font-size: 16px;
  color: var(--light-color);
}
.mjwi-ct-card .mjwi-ct-card__title {
  padding: 0;
  font-size: 1.3rem;
  font-weight: normal;
  margin: 0;
  text-transform: capitalize;
}
.mjwi-ct-card-logoname {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  margin: -5px 0 0 0;
  border-bottom: 1px solid var(--hover-color);
}
.mjwi-ct-card__title  a{
  text-transform: capitalize;
}

.mjwi-ct-search {
  margin: 30px 0;
  text-align: center;
}
.mjwi-ct-search input {
  padding: 15px;
  border-radius: 100px;
  width: 50%;
  text-align: center;
  font-family: 'Montserrat';
  border: none;
  font-size: 20px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
}
.mjwi-ct-search input:focus-visible {
  outline: none;
}
/***** Button *****/
.mjwi-ct-card-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 1.1em;
}
.mjwi-btn {
  text-decoration: none;
  background: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border-radius: 100px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  width: 48%;
  margin: 0 1%;
  border: 1px solid var(--dark-color);
}
.mjwi-setting-page .mjwi-btn, .mjwi-widgets-wrapper .mjwi-btn{
  width: auto;
  float: right;
  padding: 10px 30px;
}
.mjwi-btn:hover,
.mjwi-btn:focus {
    background-color: var(--hover-color);
    border-color: var(--hover-color);
    color: white;
}

/***** Widget *****/
.mjwi-widget-container {
    display: flex;
    gap: 10px;
    box-shadow: 0px 0px 3px #aaa;
    padding: 10px;
    border-radius: 15px;
    width: 70%;
    min-height: 100px;
}

.mjwi-widget-img {
    height: 100px;
    width: 100px;
}

.mjwi-widget-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/***** Editor *****/
.mjwi-editor-wrapper + .mjwi-footer{
    display: none;
}
.mjwi-preview-iframe{
    width: 100%;
    height: -webkit-calc(100vh - 56px);
    border: 0;
    padding: 15px;
}
.mjwi-popup {
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid var(--light-color);
    padding: 20px;
    z-index: 1000;
    text-align: center;
    min-width: 400px;
    max-height: 90vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mjwi-info-popup {
  position: absolute;
  top: 0;
  left: 20%;
  background-color: var(--dark-color);
  padding: 20px;
  z-index: 1000;
  overflow: auto;
  height: calc(100vh - 52px);
  width: 80%;
  color: #fff;
}

.mjwi-editor-wrapper {
    display: flex;
    height: calc(100vh - 52px);
    width: 100%;
    position: relative;
}

.mjwi-editor-content {
    width: 100%;
}

.mjwi-editor {
    display: flex;
    height: 100%;
    width: 100%;
}

.mjwi-editor-leftbar {
    background-color: var(--primary-color);
    display: flex;
    position: absolute;
    width: 20%;
    justify-content: space-around;
    color: var(--light-color)
}

.mjwi-editor-leftbar div {
  cursor: pointer;
  border-left: 1px solid var(--light-color);
  padding: 10px;
  width: -webkit-fill-available;
  text-align: center;
}
.mjwi-editor-leftbar .active{
    color: var(--hover-color);
}
.mjwi-editor-leftbar div:hover {
  color: var(--hover-color);
}
.mjwi-editor-leftbar div:nth-child(1) {
  border: none;
}
.mjwi-editor-main {
    width: 20%;
    display: flex;
    background-color: var(--primary-color);
    flex-direction: column;
    padding: 10px 10px 0;
    gap: 10px;
    margin-top: 40px;
    color: var(--light-color);
    overflow-y: auto;
    font-size: 14px;
} 
.mjwi-editor-builder input, .mjwi-editor-layout input, .mjwi-editor-builder select, .mjwi-editor-layout select {
  padding: 5px 10px;
  font-size: 14px;
  font-family: 'Montserrat';
  border-radius: 100px;
  border: none;
  width: 100%;
  background-color: var(--dark-color);
  color: var(--light-color);
}
.mjwi-editor-builder input[type="checkbox"], .mjwi-editor-layout input[type="checkbox"]{
  width: auto;
  accent-color: var(--dark-color);
  margin-right: 5px;
}
.mjwi-radio-svg label{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.mjwi-radio-svg input[type="radio"]:checked ~ svg{
  fill: var(--hover-color);
}
.mjwi-radio-svg input[type="radio"]{
    position: absolute;
    opacity: 0;
    width: 0;
}
.mjwi-clr-picker {
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
}
.mjwi-clr-picker .react-colorful {
  position: absolute;
  z-index: 9;
  top: 35px;
  right: 15px;
}
.clrPickerVal{
    display: block;
    width: 25px;
    height: 22px;
    border: 1px solid #fff;
    cursor: pointer;
}
.mjwi-editor-builder input:focus, .mjwi-editor-layout input:focus, .mjwi-editor-layout select:focus {
	outline: none;
}
.mjwi-editor-builder, .mjwi-editor-layout {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.mjwi-editor-bottom {
  margin-top: auto;
  margin-bottom: 10px;
}
.mjwi-editor-display {
    width: 80%;
    overflow-y: scroll;
}
.mj-widget.mode-preview{
    position: relative;
    min-height: 94.5vh;
}
#mjwi-btn-Purchase{
    width: 150px;
}

/***** Appfolio *****/
.mjwi-apfl-display-filters, .mjwi-apfl-display-card-items {
    display: flex;
    flex-direction: column;
}

/***** Embedded code *****/
/*.mjwi-popup-content {
    max-height: 600px;
    max-width: 400px;
    margin: 0 auto;
}*/ 
.mjwi-popup-content {
  line-height: 2;
}
.mjwi-editor-main i.fa-solid.fa-circle-info {
  float: right;
  font-size: 18px;
  cursor: pointer;
}
.mjwi-popup .mjwi-popup-close, .mjwi-info-popup .mjwi-popup-close {
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
  font-size: 30px;
}

.mjwi-popup .mjwi-close:hover {
    color: #000;
}

/* ------------ Profile Settings -------------- */
.mjwi-setting-page {
  padding: 30px 0 0 0;
}
  .mjwi-email-tooltip{
    display: none;
    position: absolute;
    top: -15px;
    right: 0px;
    color: green;
  }
  .mjwi-acnt-success-msg{
    display: none;
    position: absolute;
    color: green;
  }
  
  .mjwi-profile-setting,
  .mjwi-security-setting,
  .mjwi-reset-pwd,
  .mjwi-delete-account,
  .mjwi-subscription-plans {
    width: 33%;
    min-width: 400px;
    background-color: var(--primary-color);
    padding: 0 20px 20px;
    border-radius: 20px;
    border: 1px solid var(--dark-color);
  }
  
  .mjwi-profile-setting h2,
  .mjwi-security-setting h3,
  .mjwi-reset-pwd h3,
  .mjwi-delete-account h3 {
    color: var(--dark-color);
    margin-bottom: 10px;
  }
  
  .mjwi-profile-setting h3,
  .mjwi-security-setting h3,
  .mjwi-reset-pwd h3,
  .mjwi-delete-account h3,
  .mjwi-subscription-plans h3 {
    border-bottom: 2px solid var(--dark-color);
    padding-bottom: 5px;
    margin-bottom: 15px;
    color: var(--light-color);
  }
  
  .mjwi-profile-setting label,
  .mjwi-security-setting label,
  .mjwi-reset-pwd label {
    display: block;
    margin-top: 10px;
    color: var(--light-color);
  }
  
  .mjwi-profile-setting input, .mjwi-security-setting input, .mjwi-reset-pwd input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border-radius: 100px;
    border: none;
    background: var(--dark-color);
    color: var(--light-color);
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Montserrat';
}
.mjwi-reset-pwd, .mjwi-profile-setting, .mjwi-reset-new-pwd, .mjwi-profile-email {
  position: relative;
}
.mjwi-reset-new-pwd i, .mjwi-profile-email i {
    position: absolute;
    right: 10px;
    top: 16px;
    color: var(--secondary-color);
    cursor: pointer;
}

.mjwi-profile-email i.fa-solid.fa-circle-check {
  color: green;
}
.mjwi-profile-email i.fa-solid.fa-circle-exclamation {
  color: red;
}
/***** User plans *****/
.mjwi-pricing-plans-container *{
 margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Open Sans", sans-serif;
}

.mjwi-pricing-plans {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 64px;
  
}

.mjwi-pricing-card {
  --col: #e4e4e7;
  position: relative;
  min-width: 360px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 4px;
  border: 1px solid #262626;
  background-color: #26262620;
  box-shadow: 0 0 32px transparent;
  text-align: center;
}

.mjwi-pricing-card.mjwi-basic {
  --col: #0891b2;
}

.mjwi-pricing-card.mjwi-standard {
  --col: #059669;
}

.mjwi-pricing-card.mjwi-premium {
  --col: #c026d3;
}

.mjwi-pricing-card:hover {
  box-shadow: 0 0 32px #aaa;
  transform: translateY(-16px) scale(1.02);
  transition: all 0.5s ease;
}

.mjwi-pricing-card > *:not(:last-child) {
  margin-bottom: 32px;
}

.mjwi-pricing-card .mjwi-heading h4 {
  padding-bottom: 12px;
  color: var(--col);
  font-size: 24px;
  font-weight: normal;
}

.mjwi-pricing-card .mjwi-heading p {
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.mjwi-pricing-card .mjwi-price {
  position: relative;
  color: var(--col);
  font-size: 60px;
  font-weight: bold;
}

.mjwi-pricing-card .mjwi-price sub {
  position: absolute;
  bottom: 14px;
  color: #a3a3a3;
  font-size: 14px;
  font-weight: lighter;
}

.mjwi-pricing-card .mjwi-features li {
  padding-bottom: 16px;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
}

.mjwi-pricing-card .mjwi-features li i,
.mjwi-pricing-card .mjwi-features li strong {
  color: #e4e4e7;
  font-size: 16px;
  text-align: left;
}

.mjwi-pricing-card .mjwi-features li strong {
  padding-left: 24px;
}

.mjwi-pricing-card .mjwi-cta-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #e4e4e7;
  font-size: 20px;
  font-weight: bold;
}

.mjwi-pricing-card .mjwi-cta-btn:active {
  background-color: transparent;
  color: var(--col);
  transition: all 0.3s ease;
}

.mjwi-cta-curr-plan {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  color: var(--col);
  font-size: 20px;
  font-weight: bold;
}

/***** Verify password popup *****/
.mjwi-password-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 25%;
  text-align: center;
}

.mjwi-password-input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.mjwi-submit-button {
  padding: 10px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.mjwi-submit-button:hover {
  background-color: #45a049;
}


/***** Overlay *****/
.mjwi-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 999; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.mjwi-msg-wrapper{
  z-index: 9;
}

.mjwi-error {
	margin: 10px;
	color: red;
	font-size: 13px;
  display: block;
}
.mjwi-setting-page .mjwi-error {
  margin: 10px;
  color: red;
  font-size: 13px;
  bottom: 40px;
  left: 0;
}

.mjwi-error-main {
  position: absolute;
  box-shadow: 0 0 10px #000;
  padding: 10px;
  color: var(--light-color);
  background-color: var(--hover-color);
  left: 20%;
  width: 80%;
}

.mjwi-msg-main {
    position: absolute;
    color: green;
    background-color: white;
    box-shadow: 0 0 10px #000;
    padding: 10px;
    left: 20%;
    width: 80%;
}

.mjwi-hidden {
    display: none;
}

/* Widget Install popup */
.mjwi-popup.mjwi-install-popup {
  background-color: var(--light-color);
  color: var(--dark-color);
  text-align: left;  
}
.mjwi-popup.mjwi-install-popup h3{
  margin-top: 0;
}
.mjwi-textarea {
  height: 50px;
  min-height: 50px;
  width: 100%;
  resize: vertical;
  background-color: var(--dark-color);
  color: var(--light-color);
}
span.mjwi-copy-icon {
  cursor: pointer;
  position: absolute;
  right: 25px;
  color: var(--hover-color);
}
.mjwi-copy-tooltip{
    display: none;
    position: absolute;
    top: -25px;
    left: -30px;
}

/* --------- FOOTER --*/

.mjwi-footer {
  height: 52px;
  background-color: var(--dark-color);
  color: #fff;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}
.mjwi-footer a {
  text-decoration: none;
  color: #ccc;
}
.mjwi-footer a:hover {
  color: var(--hover-color);
}
.mjwi-footer-links {
  margin-left: auto;
}