/*
    Devadmin styles
*/

.mjwidev-container {
    display: flex;
    height: calc(100vh - 104px);
}
.mjwidev-container-left {
    width: 10%;
    background-color: var(--primary-color);
    color: var(--light-color);
}
.mjwidev-container-right {
    width: 90%;
}
.mjwidev-container-left ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.mjwidev-container-left ul li {
    cursor: pointer;
    padding: 10px;
}
.mjwidev-container-left ul li:hover, .mjwidev-container-left ul li.dvactive {
    background: var(--dark-color);
}
.mjwidev-container-left ul li i {
    width: 20px;
    text-align: left;
}
.mjwidev-container-left ul li:hover i, .mjwidev-container-left ul li.dvactive i{
    color: var(--hover-color);
}
.mjwidev-main {
    display: flex;
    flex-direction: column;
    padding: 0 var(--gap);
    overflow-y: scroll;
    height: calc(100vh - 104px);
    scrollbar-width: none;
}
.mjwidev-main-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mjwidev-addnew {
    width: auto;
    cursor: pointer;
}
.mjwidev-main .mjwi-ct-list {
    width: 100%;
    gap: 20px;
}
.mjwidev-main .mjwi-ct-card {
    cursor: unset;
    display: flex;
    flex-direction: column;
    min-height: 475px;
    width: 383px;
}
.mjwidev-main .mjwi-ct-card .mjwi-ct-card__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.mjwidev-main .mjwi-ct-card-desc {
    margin-top: auto;
}
.mjwidev-main .mjwi-ct-card-desc p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}
.mjwidev-main .mjwi-ct-card-desc p span {
    font-weight: normal;
    font-style: italic;
}
.mjwidev-main img.mjwi-catalog-banner {
    min-height: 229.8px;
}
.mjwidev-popup {
    position: absolute;
    top: 52px;
    left: 10%;
    background-color: var(--primary-color);
    padding: 0 20px;
    z-index: 1000;
    overflow: auto;
    height: calc(100vh - 104px);
    width: 90%;
    color: #fff;
    scrollbar-width: none;
}
.mjwidev-popup .mjwi-popup-close {
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
    font-size: 30px;
}
.mjwi-row {
    align-items: normal;
    gap: 20px;
}
.mjwidev-input-field label {
    display: block;
    margin-top: 10px;
    color: var(--light-color);
}
.mjwidev-input-field input, .mjwidev-input-field select {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border-radius: 100px;
    border: none;
    background: var(--dark-color);
    color: var(--light-color);
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Montserrat';
}
.mjwidev-pp .mjwi-col50 {
    width: 100%;
    min-width: 400px;
    background-color: var(--primary-color);
    padding: 0 20px 20px;
    border-radius: 20px;
    border: 1px solid var(--dark-color);
}
.mjwidev-bottom-row {
    margin: var(--gap) 0;
    display: flex;
    justify-content: flex-start;
}
.mjwidev-bottom-row a.mjwi-btn {
    width: 150px;
}
.mjwidev-new-banner-image {
    width: 100%;
    min-height: 350px;
    background: var(--dark-color);
}
.mjwidev-new-icon-image {
    width: 100%;
    min-height: 150px;
    background: var(--dark-color);
}
/* --------------------------------- OLD CSS ---------------------- */

/*
.mjwidev-wrapper {
    padding: 10px;
    box-shadow: 0 0 3px #aaa;
    display: flex;
    gap: 10px;
    width: 80%;
}

.mjwidev-inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mjwidev-ttl {
    font-weight: 600;
}

.mjwidev-act-btns {
    margin-left: auto;
}

.mjwidev-act-btns i{
    margin-right: 10px;
}

.mjwidev-hdng {
    font-size: large;
    font-weight: bold;
    text-transform: uppercase;
}

.mjwidev-pp {
    margin-bottom: 10px;
}

.mjwidev-pp form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.mjwidev-input-field input, .mjwidev-input-field select, .mjwidev-input-field textarea{
    width: 80%;
    height: 35px;
    padding: 8px;
}

.mjwidev-input-field textarea {
    height: 100px;
}

.accordion__button {
    background-color: unset;
}*/